// REACT
import React, { useState } from "react"
// GATSBY
import { navigate } from "gatsby-link"
// MUI
import { makeStyles } from "@material-ui/core/styles"
import { Button, TextField, Typography } from "@material-ui/core"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { Link } from "../components/link"

// STYLES
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      display: "block",
      margin: theme.spacing(4, 2),
    },
    "& .MuiInputBase-root": {
      maxWidth: "100%",
    },
  },
  name: {
    "& .MuiInputBase-input": {
      width: "29ch",
    },
  },
  email: {
    "& .MuiInputBase-input": {
      width: "47ch",
    },
  },
  message: {
    "& .MuiInputBase-input": {
      width: "76ch",
    },
  },
  button: {
    margin: theme.spacing(4, 2),
  },
}))

// FUNCTIONS
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

// COMPONENT FUNCTION
const Contact = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // STATE
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  })

  // HANDLERS
  const handleChange = event => {
    const { name, value } = event.target
    setState(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  // COMPONENT
  return (
    <Layout title="Contact">
      <PageTitle title="CONTACT" />

      <Typography paragraph>
        We're <Link to="/directions">located</Link> off Route 6 in Wellfleet,
        MA. We're open from 9am to 5pm Monday through Friday and on Saturdays
        in-season. Our phone number is 508-349-3840. You can also send us an
        email using the form below and we will reply to you promptly.
      </Typography>

      <form
        className={classes.root}
        name="contact"
        method="post"
        action="/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" onChange={handleChange} />
        <TextField
          variant="filled"
          name="name"
          label="Name"
          value={state.name}
          onChange={handleChange}
          className={classes.name}
        />
        <TextField
          variant="filled"
          name="email"
          label="Email"
          value={state.email}
          onChange={handleChange}
          required
          className={classes.email}
        />
        <TextField
          variant="filled"
          name="message"
          label="Message"
          value={state.message}
          onChange={handleChange}
          multiline
          rows="8"
          required
          className={classes.message}
        />
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          className={classes.button}
        >
          Send
        </Button>
      </form>
    </Layout>
  )
}

export default Contact
